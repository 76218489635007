
import React from 'react';
import {withRouter, Redirect} from "react-router-dom";

import {DeployInterface} from './DeployInterface'
import {OPERATION_MODE_DEPLOY_TEST_VARIATION} from "./DeployInterface";

import {queryApiGet, queryApiPost, UnauthorizedQueryError, RequestProcessingError} from '../api.js'

import slugify from 'slugify'
import ErrorComponent from "./error";

import deployEventsTrackerService from "../services/deployEventsTrackerService";
import {LocalStorageService} from "../services/localstorage.service";
import {SelectTestVariationLogicBranch, SelectTestVariationSettingsBranch} from "../startAbChildComponents";

import TextField from "@mui/material/TextField";

class StartAb extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectToAbDetailsInitiated: false,
            operationInProcess: false,
            deployComplete: false,
            errorOccurred: false,
            experimentId: "",
            testVariationSettingsBranch: 'master',
            testVariationLogicBranch: null,
            testVariationDeployed: false,
            testVariationInfo: null,
            settingsBranchesNamesLoaded: false,
            settingsBranchesNames: null,
            tagsNames: null,
            testVariationData: null,
            variationIds: null,
            showButtonVersionLogicBranch: true,
            selectVersionLogicBranch: false,
            logicAndSettingsBranch: false,
            accesses: LocalStorageService.getAccesses(),
            logicBranches: null,
            percentageForA: 50,
            percentageForB: 50,
            selectedSettingsBranch: null,
            selectedLogicVersion: null,
        };


        this.handleExperimentIdChange = this.handleExperimentIdChange.bind(this)
        this.handleTestVariationChoiceSubmit = this.handleTestVariationChoiceSubmit.bind(this)
        this.handleTestVariationDeployComplete = this.handleTestVariationDeployComplete.bind(this)
        this.handleExperimentFormSubmit = this.handleExperimentFormSubmit.bind(this)
        this.handleLogicBranchChoice = this.handleLogicBranchChoice.bind(this)
        this.processError = this.processError.bind(this)
        this.handleLogicTypeChange = this.handleLogicTypeChange.bind(this)


    }

    handleLogicTypeChange(logicType) {
        this.setState({logicType})
    }
    componentDidMount() {
        this.loadSettingsBranchesNames()
        this.loadTagsNames();
        this.loadTestVariationData()
        this.loadVariationIds()

        if (this.state.accesses.useLogicBranchInAB) {
            this.loadLogicBranches()
        }
    }

    loadLogicBranches() {
        queryApiGet('/api/logicBranchNames').then((logicBranches) => {
            this.setState({logicBranches: logicBranches.reverse()})
        })
    }

    processError(e) {
        if (e instanceof UnauthorizedQueryError) {
            console.error('Unauthorized')
        } else if (e instanceof RequestProcessingError) {
            console.error(e.message)

            this.setState({
                errorOccurred: true
            })
        } else {
            throw e
        }
    }

    loadVariationIds() {
        queryApiGet(
            `/api/suggestABVariationIds?siteId=${this.props.match.params.siteId}`
        ).then((data) => {
            this.setState({
                variationIds: data.variationIds
            })
        }).catch((err) => this.setErrorState(err))
    }

    loadSettingsBranchesNames() {
        deployEventsTrackerService.trackSettingsBranchLoadingStarted();

        queryApiGet(
            '/api/settingsBranchNames'
        ).then(data => {
             deployEventsTrackerService.trackSettingsBranchLoadingFinished()

             this.setState({
                 settingsBranchesNamesLoaded: true,
                 settingsBranchesNames: data
             })
         }).catch((err) => {
             deployEventsTrackerService.trackSettingsBranchLoadingFailed(err)
             this.setErrorState(err)
        })
    }

    setErrorState(errorMessage) {
        this.setState({errorMessages: [...this.state.errorMessages || [], errorMessage]})
    }

    loadTagsNames() {
        deployEventsTrackerService.trackHigherVersionTagsLoadingStarted()

        queryApiGet(
            `/api/higherVersionTagsNames?siteId=${this.props.match.params.siteId}`
        ).then(data => {
            deployEventsTrackerService.trackHigherVersionTagsLoadingFinished()

            this.setState({
                tagsNames: data
            })
        }).catch((err) => {
            deployEventsTrackerService.trackHigherVersionTagsLoadingFailed(err)
            this.setErrorState(err)
        })
    }

    loadTestVariationData() {
        queryApiGet(
            `/api/siteStatus?siteId=${this.props.match.params.siteId}`
        ).then(data => {
                this.setState({
                    testVariationData: data,
                    testVariationLogicBranch: data.version.logic.commitish,
                    testVariationLogicHashBranch: data.version.logic.hash.substring(0, 6),
                    testVariationSettingsHashBranch: data.version.settings.hash.substring(0, 6)
                })
        }).catch((err) => this.setErrorState(err))
    }

    handleLogicBranchChoice() {
        this.setState({
            showButtonVersionLogicBranch: false,
            selectVersionLogicBranch: true
        })
    }

    handleTestVariationDeployComplete(deployedVersionInfo) {
        this.setState({
            testVariationDeployed: true,
            testVariationInfo: deployedVersionInfo
        })
    }

    handleTestVariationChoiceSubmit() {
        const variationSettingsBranch = this.state.selectedSettingsBranch

        this.setState({
            testVariationSettingsBranch: variationSettingsBranch,
            logicAndSettingsBranch: true
        })

        // #Todo заменить обращение к хтмл элементу на onChange
        if (document.getElementById('logic_branch')) {
            let variationLogicBranch = document.getElementById('logic_branch').value

            if (this.state.logicType === 'branch') {
                variationLogicBranch = 'origin/' + variationLogicBranch
            }

            this.setState({
                testVariationLogicBranch: variationLogicBranch
            })
        }

    }

    handleExperimentIdChange(e) {
        const initialStr = e.target.value

        const slygifyStr = slugify(initialStr, {replacement: '_', lower: true})
        const strAfterSanification = slygifyStr.replace(/[^a-zA-Z0-9./_-]/g, '');

        this.setState({
            experimentId: strAfterSanification
        })
    }

    adjustSecondVariationPercentageAfterInput(variation, event) {
        let value = event.target.value

        // Todo find the way to path min and max value to mui input
        if (value > 99) value = 99
        if (value < 1) value = 1

        if (variation === 'a') {
            this.setState({
                percentageForB: 100 - value,
                percentageForA: value
            })
        }

        if (variation === 'b') {
            this.setState({
                percentageForA: 100 - value,
                percentageForB: value
            })
        }
    }

    handleExperimentFormSubmit(e) {
        e.preventDefault()

        const d = new FormData(e.target)

        this.setState({
            operationInProcess: true
        })

        deployEventsTrackerService.trackAbStarted()

        queryApiPost(
             `/api/startAb?siteId=${this.props.match.params.siteId}&experimentId=${d.get('experiment_id')}&variationIds=${this.state.variationIds.join(',')}&split=${[this.state.percentageForA, this.state.percentageForB]}`,
            {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(() => {
            deployEventsTrackerService.trackABbFinished()

            this.setState({
                operationInProcess: false,
                deployComplete: true
            })

            setTimeout(() => {
                this.setState({
                    redirectToAbDetailsInitiated: true
                })
            }, 1500)
        })
        .catch((err) => {
            deployEventsTrackerService.trackAbFailed(err.errorMessage)

            this.setErrorState(err)
        })
    }

    getSubmitButtonDisabled() {
       if (this.state.showButtonVersionLogicBranch) {
           return !(this.state.selectedSettingsBranch)
       }

       if (!this.state.showButtonVersionLogicBranch) {
           return !(this.state.selectedSettingsBranch && this.state.selectedLogicVersion)
       }
    }

    onSettingsBranchSelected(settingsBranch) {
        this.setState({selectedSettingsBranch: settingsBranch})
    }

    onLogicVersionSelected(logicVersion) {
        this.setState({selectedLogicVersion: logicVersion})
    }

    render () {
        if (this.state.errorMessages?.length) {
            return (<ErrorComponent errorMessages={this.state.errorMessages} />)
        } else if (!this.state.tagsNames || !this.state.testVariationData || !this.state.settingsBranchesNamesLoaded || !this.state.variationIds) {
            return 'Gathering information. Please wait ...'
        } else if (this.state.redirectToAbDetailsInitiated) {
            return (
                <Redirect
                    to={`/experimentDescriptionGenerator/${this.props.match.params.siteId}/${this.state.experimentId}/${this.state.variationIds.join(',')}/${[this.state.percentageForA, this.state.percentageForB].map(el => el / 100)}`}
                />
            )
        } else if (this.state.deployComplete) {
            return <span style={{fontSize: 25, backgroundColor: '#009933', color: 'Yellow'}}>Experiment has started!</span>
        } else if (this.state.operationInProcess) {
            return 'Please wait ...'
        } else if (!this.state.logicAndSettingsBranch) {
            return (
                <form style={{minWidth: '60vw'}}>
                    <div>
                        Variations: {this.state.variationIds.join(', ')}
                    </div>
                    <SelectTestVariationLogicBranch
                        selectVersionLogicBranch={this.state.selectVersionLogicBranch}
                        handlerLogicBranch={this.handleLogicBranchChoice}
                        handleLogicTypeChange={this.handleLogicTypeChange}
                        accesses={this.state.accesses}
                        testVariationData={this.state.testVariationData}
                        siteId={this.state.siteId}
                        tagsNames={this.state.tagsNames}
                        logicBranches={this.state.logicBranches}
                        selectUpdate={this.state.selectVersionLogicBranch}
                        showButtonVersionLogicBranch={this.state.showButtonVersionLogicBranch}
                        onLogicVersionSelected={this.onLogicVersionSelected.bind(this)}
                        testVariationId={this.state.variationIds[1]}

                    />
                    <SelectTestVariationSettingsBranch
                        settingsBranchesNames={this.state.settingsBranchesNames}
                        testVariationId={this.state.variationIds[1]}
                        onSettingsBranchSelected={this.onSettingsBranchSelected.bind(this)}
                    />
                    <button disabled={this.getSubmitButtonDisabled()} onClick={() => this.handleTestVariationChoiceSubmit()} style={{width: '250px', height: '60px', fontSize: 25, marginTop: '20px', cursor: 'pointer'}}>Continue</button>
                </form>
            )
        } else if (!this.state.testVariationDeployed) {
            return <DeployInterface
                operationMode={OPERATION_MODE_DEPLOY_TEST_VARIATION}
                variation={this.state.variationIds[1]}
                siteId={this.props.match.params.siteId}
                settingsBranch={this.state.testVariationSettingsBranch}
                settingsHashBranch={this.state.testVariationSettingsHashBranch}
                logic={this.state.testVariationLogicBranch}
                logicHash={this.state.testVariationLogicHashBranch}
                onDeployComplete={this.handleTestVariationDeployComplete}
            />
        }


        return (

            <form onSubmit={this.handleExperimentFormSubmit}>
                <table cellSpacing={10}>
                    <tbody>
                        <tr>
                            <td>Experiment ID:</td>
                            <td><input onChange={this.handleExperimentIdChange} style={{width: '100%'}} required pattern=".{3,50}"/></td>
                        </tr>
                        <tr>
                            <td>
                                Experiment ID (final):
                            </td>
                            <td>
                                {this.state.experimentId}
                                <input type="hidden" name="experiment_id" value={this.state.experimentId}/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Variation {this.state.variationIds[0]}:
                            </td>
                            <td>
                                current version used on production
                            </td>
                            <td>
                                <span style={{marginLeft: '15px' }}> Percentage for variation A: {this.state.percentageForA}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Settings branch for variation {this.state.variationIds[1]}:
                            </td>
                            <td>
                                Logic: {this.state.testVariationInfo.logic.commitish} / {this.state.testVariationInfo.logic.hash.substring(0, 6)}<br />
                                Settings: {this.state.testVariationInfo.settings.commitish} / {this.state.testVariationInfo.settings.hash.substring(0, 6)}<br />
                            </td>
                            <td>
                                <TextField
                                    onInput={(event) => this.adjustSecondVariationPercentageAfterInput('b', event)}
                                    id="percentageForB"
                                    label="Percent for B"
                                    type="number"
                                    variant="filled"
                                    value={this.state.percentageForB}
                                    slotProps={{
                                        inputLabel: {
                                            shrink: true
                                        }
                                    }}
                                />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" style={{width: '250px', height: '60px', fontSize: 25}}>Start A/B</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        )

    }
}



export default withRouter(StartAb)
